<template>
  <cs-page class="cs-page-docs bg-white">
    <div class="d-flex">
      <div style="padding-right: 40px">
        <a-layout-sider
          class="sider-content position-sticky"
          width="272"
          style="top: 60px"
        >
          <div class="sider-title">文档中心</div>
          <a-menu
            class="menu-conten"
            mode="inline"
            :defaultOpenKeys="formModel.defaultOpenKeys"
            :defaultSelectedKeys="formModel.defaultSelectedKeys"
          >
            <a-sub-menu v-for="menu in source.siderMenuList" :key="menu.key">
              <span slot="title"
                ><a-icon :type="menu.icon" /><span>{{ menu.title }}</span></span
              >
              <a-menu-item
                v-for="childMenu in menu.children"
                :key="childMenu.key"
                @click="menuClick(childMenu)"
                >{{ childMenu.title }}</a-menu-item
              >
            </a-sub-menu>
          </a-menu>
        </a-layout-sider>
      </div>
      <div style="width: 100%">
        <slot></slot>
      </div>
    </div>
    <a-back-top style="right: 15px" />
  </cs-page>
</template>

<script>
export default {
  data() {
    return {
      formModel: {
        articleTitle: "",
        defaultOpenKeys: [],
        defaultSelectedKeys: [this.$route.path],
      },
      source: {
        siderMenuList: [
          {
            title: "使用协议",
            icon: "caret-down",
            key: "agreement",
            children: [
              {
                title: "微海翘财用户使用协议",
                key: "/docs/user",
                path: "/docs/user",
              },
            ],
          },
          {
            title: "隐私政策",
            icon: "caret-down",
            key: "policy",
            children: [
              {
                title: "微海翘财隐私政策",
                key: "/docs/privacy",
                path: "/docs/privacy",
              },
            ],
          },
          {
            title: "帮助文档",
            icon: "caret-down",
            key: "help",
            children: [
              {
                title: "常见问题",
                key: "/docs/help",
                path: "/docs/help",
              },
            ],
          },
        ],
      },
    };
  },

  created() {
    this.initData();
  },

  methods: {
    initData() {
      let { formModel, source } = this;
      let path = this.$route.path;
      source.siderMenuList.forEach((item) => {
        item.children.forEach((childItem) => {
          if (path === childItem.path) {
            formModel.articleTitle = childItem.title;
          }
          formModel.defaultOpenKeys.push(item.key);
        });
      });
    },

    menuClick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.cs-page-docs {
  /deep/ .main-body {
    width: 1280px;
    margin: 0 auto;
    padding: 40px;
  }
  /deep/ .ant-layout-sider-children {
    border: 1px solid #ededed;
  }
  /deep/ .ant-menu {
    &.ant-menu-inline {
      border-right: none;
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-item::after {
      border-right: none;
    }

    .ant-menu-item-selected {
      background: rgba(33, 189, 176, 0.04) !important;
    }

    .ant-menu-submenu-selected {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #666666;
    }
  }
  .sider-content {
    position: relative;
    height: 650px;
    background: url("../../../../public/img/sider-bg.png") no-repeat right
      bottom;
    .sider-title {
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #333333;
      font-weight: 500;
      letter-spacing: 0;
      border-bottom: 1px solid #ededed;
      height: 73px;
      line-height: 73px;
      padding-left: 40px;
    }
  }

  .article-content {
    height: 100%;
    position: relative;
    padding: 0 24px;
    header {
      border-bottom: 1px solid #f0f0f0;
    }

    h1 {
      font-family: PingFangSC-Medium;
      font-size: 36px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 500;
    }

    .main-content {
      padding: 30px 0;
      height: calc(100% - 80px);
      overflow: auto;
    }
  }
}
</style>